import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import styles from './ClassicHeader.module.scss';
import { Breadcrumb, UploadedImage } from '../../../types';
import { concatPath, allowScroll, preventScroll } from '../../../helpers';
import { useHubContext } from '../../hub/shared/context/hubContext';
import { Anchor } from '../../shared/Anchor';
import ImageTag from '../../shared/ImageTag/ImageTag';
import { Heading } from './Heading';
import { TextContent } from './TextContent';
import { BurgerIcon } from './BurgerIcon';
import { SearchField } from './SearchField';
import { Breadcrumbs } from './Breadcrumbs';
import { SearchButton } from './SearchButton';
import UserPreferences from '../../hub/shared/UserPreferences';

export interface ClassicHeaderProps {
  logo?: UploadedImage;
  logoAlt?: string;
  heading?: string;
  displayBrandLogo?: boolean;
  displaySearch?: boolean;
  displayMobileSearchButton?: boolean;
  searchPlaceholderText?: string;
  textContent?: string;
  displayTextContent?: boolean;
  breadcrumbs?: Breadcrumb[];
  fullScreen?: boolean;
  mobileNavBarContent?: React.ReactNode;
}

export default function ClassicHeader(props: ClassicHeaderProps) {
  const {
    logo,
    logoAlt,
    heading,
    textContent,
    displayBrandLogo,
    displaySearch,
    displayMobileSearchButton,
    searchPlaceholderText,
    displayTextContent,
    breadcrumbs,
    mobileNavBarContent,
    fullScreen,
  } = props;
  const { indexPath } = useHubContext();
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [mobileSearchOpen, setMobileSearchOpen] = useState<boolean>(false);

  const searchHandler = useCallback(
    (searchValue: string) => {
      const value = encodeURIComponent(searchValue);
      const url = concatPath([indexPath, '/search/']);
      document.location.assign(`${url}?searchInput=${value}`);
    },
    [indexPath],
  );

  const toggleMobileMenuHandler = useCallback(() => {
    if (mobileMenuOpen) {
      allowScroll();
    } else {
      preventScroll();
    }

    setMobileMenuOpen(!mobileMenuOpen);
  }, [mobileMenuOpen]);

  const toggleMobileSearchHandler = useCallback(() => {
    setMobileSearchOpen(!mobileSearchOpen);
  }, [mobileSearchOpen]);

  const componentClassName = cn(styles.wrapper, {
    [styles.largeWrapper]: heading,
  });

  const containerClassName = cn({
    'container-fluid': fullScreen,
    container: !fullScreen,
  });

  return (
    <div className={componentClassName}>
      <div className={containerClassName}>
        <div className="row">
          <div className="col-xs-12">
            <div className={styles.body}>
              <div className={styles.bodyLeft}>
                {breadcrumbs && (
                  <div className={styles.breadcrumbsWrapper}>
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                  </div>
                )}
                {heading && (
                  <div className={styles.headingWrapper}>
                    <Heading>{heading}</Heading>
                  </div>
                )}
                {displayBrandLogo && logo && (
                  <div className={styles.logo}>
                    <Anchor to="/" pathPrefix={indexPath}>
                      <ImageTag src={logo.path} alt={logoAlt} lazy={false} />
                    </Anchor>
                  </div>
                )}
              </div>
              <div className={styles.bodyRight}>
                {displayTextContent && textContent && (
                  <div className={styles.textContentWrapper}>
                    <TextContent>{textContent}</TextContent>
                  </div>
                )}
                {displaySearch && (
                  <div className={styles.searchWrapper}>
                    <SearchField
                      onSearch={searchHandler}
                      placeholder={searchPlaceholderText}
                    />
                  </div>
                )}
                <UserPreferences />
                {displayMobileSearchButton && (
                  <div
                    data-testid="classic-header-search-button"
                    className={styles.mobileSearchButtonWrapper}
                  >
                    <SearchButton
                      open={mobileSearchOpen}
                      onClick={toggleMobileSearchHandler}
                    />
                  </div>
                )}
                {mobileNavBarContent && (
                  <div className={styles.burgerIcon}>
                    <BurgerIcon
                      open={mobileMenuOpen}
                      onClick={toggleMobileMenuHandler}
                    />
                  </div>
                )}
              </div>
            </div>
            {displayTextContent && textContent && (
              <div className={styles.textContentWrapperMobile}>
                <TextContent>{textContent}</TextContent>
              </div>
            )}
          </div>
        </div>
      </div>
      {(mobileMenuOpen || mobileSearchOpen) && (
        <div className={styles.mobileNavBar}>
          {mobileSearchOpen && (
            <div className={styles.mobileSearchWrapper}>
              <SearchField
                onSearch={searchHandler}
                placeholder={searchPlaceholderText}
              />
            </div>
          )}
          {mobileMenuOpen && mobileNavBarContent}
        </div>
      )}
    </div>
  );
}
